body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


h2{
  font-size: 26px !important;
}

.appContainer{
  width:75%
}
  
  @media (max-width:1500px){
    .appContainer{
      width:95%
    }
  }

.textTitle {
  text-align: center;
  margin: auto;
  margin-top: 50px !important;
  margin-bottom: 20px;
}

button.btn.btn-primary {
  border-radius: 0px;
  padding: 8px 30px;
  font-size: 20px;
  background-color: #0063c3;
  border-color: #0063c3;
}

.buttonContainer {
  margin-bottom: 20px;
  width: 60%;
}

.modal-dialog {
  max-width: 1000px !important;
}

.DropdownContainer{
  margin-bottom: 20px;
  margin-left: 32px;
  width: 40%;
  display: flex;
}

.infoBox {
  background-color: #0063c31a;
  margin-left: 0px !important;
  margin-right: 0px !important;
  margin-bottom: 18px;
  padding: 13px 10px;
}

.footnote {
    margin-top: 20px;
    margin-bottom: 50px;
}

span.drugNames {
  margin-right: 30px;
}

#introCross {
    position: absolute;
    right: 0;
    top: -18px;
    font-weight: 700;
    font-size: 26px;
    transform: rotate( 
45deg);
    cursor: pointer;
    background-color: transparent;
    margin: 0px !important;
    padding: 0px !important;
    border: none;
}

.introTextContainer{
  position: relative;
}


select#countySelectFilter {
  height: 45px;
  border-radius: 0px;
  width: 100%;
  background-color: #f1f1f1;
  float: right;
  margin-right: 20px;
  font-size: 20px;
}

select#countySelect {
  height: 45px;
  border-radius: 0px;
  width: 43%;
  background-color: #f1f1f1;
  float: right;
  margin-right: 165px;
  font-size: 20px;
}

.outerButtonContainer {
  display: flex;
}

button.btn.btn-primary.active {
  background-color: #034484;
}

select.minimal {
  background-image: linear-gradient(
45deg, transparent 50%, gray 50%), linear-gradient(
135deg, gray 50%, transparent 50%), linear-gradient(to right, #ccc, #ccc);
    background-position: calc(100% - 24px) calc(1em + 2px), calc(100% - 19px) calc(1em + 2px), calc(100% - 2.5em) 0.5em;
    background-size: 5px 5px, 5px 5px, 1px 1.5em;
    background-repeat: no-repeat;
}